import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { configs, downloadCSVData, downloadDoc, formatCurrency, formatNumber, showDocNewTab } from '../../../assets/Config';
import '../../../assets/styles/CustomerProfile.css'
import { getDistributorCommissionSheet, getDistributorData } from '../../../services/apis/Distributor/DistributorApi';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useBuyOrderContext } from '../../../services/contexts/BuyOrdersContext';
import { useDistributorContext } from '../../../services/contexts/DistributorContext';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { useSellOrderContext } from '../../../services/contexts/SellOrdersContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';


export default function DistributorProfilePage() {

    const { kycRequesterUserComplete, setKycRequesterUserComplete, getKYCCustomerDetail, panDoc, addressDoc, bankDoc, dematDoc, otherDoc, kycRequesterUserDetail, setKycRequesterUserDetail } = useDistributorContext()
    const { setCurrModule, token, logout, setLoginError, allowedFunc } = useLogin()

    const [curTab, setCurTab] = useState("profile")

    const [tableData, setTableData] = useState("")

    const history = useHistory()

    let { customerId } = useParams()

    useEffect(() => {
        if (customerId) {
            setKycRequesterUserDetail({ id: customerId })
        }

    }, []);

    useEffect(() => {
        if (allowedFunc.includes(configs.functionList['distributor_management:load_distributor_details'])) {
            setCurrModule(configs.modules.distProf)
            try {
                (async () => {
                    let result = await getKYCCustomerDetail("custProf")
                })()
            } catch (error) {

            }
        } else {
            return history.goBack()
        }
    }, [kycRequesterUserDetail])


    // get distributor data
    async function getDistributorDataByType(type) {
        try {
            setTableData("")
            let response = await getDistributorData(token, kycRequesterUserDetail.id, type)
            if (response && response.status === 200) {
                if (type.toLowerCase() === "commission") {
                    setTableData(response.data.data)
                } else {
                    setTableData(response.data)
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }

    const orderDetailCard = (element, type) => {
        try {
            return (
                <div className="dashCard">
                    <div className="row m-0 align-items-center">
                        <div className="col-6 m-0 p-0">
                            <div className={`${type == "sell" ? "sellButton" : "buyButton"}`}>
                                <p className="m-0 p-0 f-12">{type == "sell" ? "Sell" : "Buy"}</p>
                            </div>
                        </div>
                        <div className="col-6 m-0 mt-2 p-0 text-right">
                            <p className="m-0 p-0 f-12">Id: <b>{element.order_id}</b></p>
                        </div>
                        <div className="col-12 m-0 mt-2 p-0">
                            <p className="m-0 p-0 font-weight-bold">{element.company_name}</p>
                        </div>
                        <div className="col-8 m-0 mt-2 p-0">
                            <p className="m-0 p-0 f-12">Price/Share: <b>{formatCurrency(parseFloat(element.order_price_per_share ? element.order_price_per_share : 0))}</b></p>
                        </div>
                        <div className="col-4 m-0 mt-2 p-0 text-right">
                            <p className="m-0 p-0 f-12">Qty: <b>{formatNumber(parseFloat(element.order_quantity ? element.order_quantity : 0))}</b></p>
                        </div>
                        <div className="col-12 m-0 mt-2 p-0">
                            <p className="m-0 p-0 f-12">{element.order_date} {element.order_time}</p>
                        </div>
                        {/* <div className="col-6 m-0 mt-2 p-0 text-right">
                            <p className="m-0 p-0 f-12">{element.order_time}</p>
                        </div> */}
                    </div>
                </div>
            )
        } catch (error) {
            console.error(error)
        }
    }

    // download csv from server
    async function downloadFile() {
        try {
            let response = await getDistributorCommissionSheet(token, kycRequesterUserDetail.id);
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = response.data ? `Commission Data` : null; //File name Here
                a.mime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                a.click();
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }

    // customer kyc main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='custProfContainer' className="kyc-container kyc-main">
                    <div className="container-fluid">
                        {kycRequesterUserComplete ? <div className="position-relative px-2 pt-2">

                            <div className="row d-flex align-items-start">
                                <div className="col-8">
                                    <p className="f-14 font-weight-bold text-primary mb-0">{kycRequesterUserComplete.basic_details.name}</p>
                                    <p className="f-12 text-custom-grey mb-0">{kycRequesterUserComplete.email}</p>
                                </div>
                                <div className="col-4 text-right">
                                    {curTab !== "profile" && tableData && tableData.length > 0 ?
                                        <button className="custom-button text-primary font-weight-bold ml-3" onClick={(e) => {
                                            e.preventDefault()
                                            downloadCSVData(tableData, `${kycRequesterUserComplete.basic_details.name} ${curTab}`)
                                        }}><i className="fas fa-cloud-download-alt mr-2"></i>Download</button>
                                        : null}
                                </div>
                                {/* <div className="col-4 text-right f-14">
                                <a href="#" className="custom-link"><i className="fas fa-pen mr-2"></i>Edit</a>
                            </div> */}
                            </div>
                            <div className="menu-list f-14">
                                <ul className="style-menu profile">
                                    <li><a href="#profile" className={curTab === "profile" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("profile")
                                    }}>Profile</a></li>
                                    <li><a href="#clients" className={curTab === "ALL Clients" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("ALL Clients")
                                        getDistributorDataByType("clients")
                                    }}>All Clients</a></li>
                                    <li><a href="#orders" className={curTab === "Client Buy Orders" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("Client Buy Orders")
                                        getDistributorDataByType("clientOrders")
                                    }}>Client Buy Orders</a></li>
                                    <li><a href="#orders" className={curTab === "Client Sell Orders" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("Client Sell Orders")
                                        getDistributorDataByType("clientOrders/sell")
                                    }}>Client Sell Orders</a></li>
                                    <li><a href="#comission" className={curTab === "Commission" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("Commission")
                                        getDistributorDataByType("commission")
                                    }}>Commission</a></li>
                                </ul>
                            </div>

                            <div className="custProf-main mt-3">

                                {/* <!-- profile section --> */}

                                {curTab === "profile" ? <div className="row">
                                    <div className="col-12 f-14 text-custom-grey justify-content-center">
                                        Name : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.basic_details.name}</span> <br />
                                        Mobile : <a href={"https://wa.me/" + kycRequesterUserComplete.mobile} target="_blank"><span className="font-wight-bold">{kycRequesterUserComplete.mobile ? `+${kycRequesterUserComplete.mobile}` : kycRequesterUserComplete.mobile}</span></a> <br />
                                        PAN : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.basic_details.pan_number}</span> <br />
                                        Address : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.basic_details.address}</span> <br />
                                        Partner Type : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.type}</span> <br />
                                        Commission Model : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.commission_modal}</span> <br />
                                        Commission Percentage (%) : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.commission_percentage}</span> <br /> <br />
                                        <span className="text-primary f-16 font-wight-bold">Demat Details</span> <br />
                                        DP Id : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.demat_details.dp_id}</span> <br />
                                        Client Id : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.demat_details.client_id}</span> <br /> <br />
                                        <span className="text-primary f-16 font-wight-bold">Bank Details</span> <br />
                                        Bank Name : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.bank_details.bank_name}</span> <br />
                                        IFSC : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.bank_details.ifsc_code}</span> <br />
                                        Acc. No. : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.bank_details.account_number}</span> <br /> <br />
                                        <span className="text-primary f-16 font-wight-bold">Uploaded Documents</span> <br />
                                        <div className="my-3 d-flex justify-content-lg-start justify-content-center cust-prof-doc-parent">

                                            <div className="docs-parent">
                                                Pan Document
                                                <span>Password - <label className="text-custom-greyDark">{kycRequesterUserComplete.distributor_documents.pan_card_password}</label></span>
                                                <div className="docs-box-profile">
                                                    {panDoc.includes("application/pdf") ? <iframe id="custProfpanFrame" height="100%" width="100%"></iframe> : panDoc.includes("image") ? <img width="100%" height="100%" src={panDoc} /> : null}
                                                    {(panDoc.includes("application/pdf") || panDoc.includes("image")) ?
                                                        <div className="kyc-openDoc-prof-container">
                                                            <i className="fa fa-external-link kyc-openDoc-prof curPoint" onClick={() => {
                                                                showDocNewTab("Pan Document", panDoc)
                                                            }}></i>
                                                            <i className="fa fa-download kyc-openDoc-prof curPoint" onClick={() => {
                                                                downloadDoc(`${kycRequesterUserComplete.basic_details.name} Pan Document`, panDoc)
                                                            }}></i>
                                                        </div>
                                                        : !kycRequesterUserComplete.distributor_documents.pan_card ? <h5>No Document Uploaded</h5> : <div className="loader"></div>}
                                                </div>
                                            </div>
                                            {/* <div className="docs-parent">
                                            Address Proof
                                            <span>Password - <label className="text-custom-greyDark">{kycRequesterUserComplete.distributor_documents.address_proof_password}</label></span>
                                            <div className="docs-box-profile">
                                                {addressDoc.includes("application/pdf") ? <iframe id="custProfaddressFrame" height="100%" width="100%"></iframe> : addressDoc.includes("image") ? <img width="100%" height="100%" src={addressDoc} /> : null}
                                                {(addressDoc.includes("application/pdf") || addressDoc.includes("image")) ?
                                                    <div className="kyc-openDoc-prof-container">
                                                        <i className="fa fa-external-link kyc-openDoc-prof curPoint" onClick={() => {
                                                            showDocNewTab("Address Document", addressDoc)
                                                        }}></i>
                                                        <i className="fa fa-download kyc-openDoc-prof curPoint" onClick={() => {
                                                            downloadDoc(`${kycRequesterUserComplete.basic_details.name} Address Document`, addressDoc)
                                                        }}></i>
                                                    </div>
                                                    : !kycRequesterUserComplete.distributor_documents.address_proof ? <h5>No Document Uploaded</h5> : <div className="loader"></div>}
                                            </div>
                                        </div> */}
                                            <div className="docs-parent">
                                                Bank Account Proof
                                                <span>Password - <label className="text-custom-greyDark">{kycRequesterUserComplete.distributor_documents.bank_proof_password}</label></span>
                                                <div className="docs-box-profile">
                                                    {bankDoc.includes("application/pdf") ? <iframe id="custProfbankFrame" height="100%" width="100%"></iframe> : bankDoc.includes("image") ? <img width="100%" height="100%" src={bankDoc} /> : null}
                                                    {(bankDoc.includes("application/pdf") || bankDoc.includes("image")) ?
                                                        <div className="kyc-openDoc-prof-container">
                                                            <i className="fa fa-external-link kyc-openDoc-prof curPoint" onClick={() => {
                                                                showDocNewTab("Bank Document", bankDoc)
                                                            }}></i>
                                                            <i className="fa fa-download kyc-openDoc-prof curPoint" onClick={() => {
                                                                downloadDoc(`${kycRequesterUserComplete.basic_details.name} Bank Document`, bankDoc)
                                                            }}></i>
                                                        </div>
                                                        : !kycRequesterUserComplete.distributor_documents.bank_proof ? <h5>No Document Uploaded</h5> : <div className="loader"></div>}
                                                </div>
                                            </div>
                                            {/* <div className="docs-parent">
                                            Demat Account Proof
                                            <span>Password - <label className="text-custom-greyDark">{kycRequesterUserComplete.distributor_documents.demat_proof_password}</label></span>
                                            <div className="docs-box-profile">
                                                {dematDoc.includes("application/pdf") ? <iframe id="custProfdematFrame" height="100%" width="100%"></iframe> : dematDoc.includes("image") ? <img width="100%" height="100%" src={dematDoc} /> : null}
                                                {(dematDoc.includes("application/pdf") || dematDoc.includes("image")) ?
                                                    <div className="kyc-openDoc-prof-container">
                                                        <i className="fa fa-external-link kyc-openDoc-prof curPoint" onClick={() => {
                                                            showDocNewTab("Demat Document", dematDoc)
                                                        }}></i>
                                                        <i className="fa fa-download kyc-openDoc-prof curPoint" onClick={() => {
                                                            downloadDoc(`${kycRequesterUserComplete.basic_details.name} Demat Document`, dematDoc)
                                                        }}></i>
                                                    </div>
                                                    : !kycRequesterUserComplete.distributor_documents.demat_proof ? <h5>No Document Uploaded</h5> : <div className="loader"></div>}
                                            </div>
                                        </div> */}
                                            <div className="docs-parent">
                                                Other Document
                                                <span>Password - <label className="text-custom-greyDark">{kycRequesterUserComplete.distributor_documents.other_document_password}</label></span>
                                                <div className="docs-box-profile">
                                                    {otherDoc.includes("application/pdf") ? <iframe id="custProfOtherFrame" height="100%" width="100%"></iframe> : otherDoc.includes("image") ? <img width="100%" height="100%" src={otherDoc} /> : null}
                                                    {(otherDoc.includes("application/pdf") || otherDoc.includes("image")) ?
                                                        <div className="kyc-openDoc-prof-container">
                                                            <i className="fa fa-external-link kyc-openDoc-prof curPoint" onClick={() => {
                                                                showDocNewTab("Other Document", otherDoc)
                                                            }}></i>
                                                            <i className="fa fa-download kyc-openDoc-prof curPoint" onClick={() => {
                                                                downloadDoc(`${kycRequesterUserComplete.basic_details.name} Other Document`, otherDoc)
                                                            }}></i>
                                                        </div>
                                                        : !kycRequesterUserComplete.distributor_documents.other_document ? <h5>No Document Uploaded</h5> : <div className="loader"></div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div> : null}
                                {(curTab === "ALL Clients") ?
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                {tableData && tableData.length > 0 ? <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="border-left-0">Name</th>
                                                            <th scope="col" className="border-left-0 text-right">Account Type</th>
                                                            <th scope="col" className="border-left-0 text-right">Investment</th>
                                                            <th scope="col" className="border-left-0 text-right">Profit/Loss</th>
                                                            <th scope="col" className="border-left-0 text-right">Portfolio</th>
                                                            <th scope="col" className="border-left-0">KYC Status</th>
                                                            <th scope="col" className="border-right-0 clientRemarkWidth">Remark</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tableData.map((element, key) => {
                                                            return (
                                                                <tr key={`tableData` + key}>
                                                                    <td className="border-left-0">
                                                                        <div className="tableBreakWord">
                                                                            <p className="m-0 p-0 f-12 font-weight-bold">{element.name}</p>
                                                                            <p className="m-0 p-0 f-12">{element.email}</p>
                                                                            <p className="m-0 p-0 f-12">+{element.mobile}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td className="border-left-0 text-right">{element.account_type}</td>
                                                                    <td className="border-left-0 text-right">{formatCurrency(parseFloat(element.total_investment))}</td>
                                                                    <td className="border-left-0 text-right">{formatCurrency(parseFloat(element.profit_and_loss))}</td>
                                                                    <td className="border-left-0 text-right">{formatCurrency(parseFloat(element.current_value))}</td>
                                                                    <td className="border-left-0 text-capitalize">{element.status}</td>
                                                                    <td className="border-right-0">{element.remark}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table> : <p>No Client details found for the distributor</p>}
                                            </div>
                                        </div>

                                    </div>

                                    : null}

                                {(curTab === "Client Buy Orders") ?
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                {tableData && tableData.length > 0 ? <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="border-left-0">Name</th>
                                                            <th scope="col" className="border-left-0 orderDetailColWidth">Order Details</th>
                                                            <th scope="col" className="border-left-0">Status</th>
                                                            <th scope="col" className="border-left-0 text-right">Amount</th>
                                                            <th scope="col" className="border-left-0 text-right">Upmark</th>
                                                            <th scope="col" className="border-left-0 text-right">Upmark Final Pay</th>
                                                            <th scope="col" className="border-right-0 text-right">Commission</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tableData.map((element, key) => {
                                                            return (
                                                                <tr key={`tableData` + key}>
                                                                    <td className="border-left-0">
                                                                        <div className="tableBreakWord">
                                                                            <p className="m-0 p-0 f-12 font-weight-bold">{element.user_name}</p>
                                                                            <p className="m-0 p-0 f-12">{element.user_email}</p>
                                                                            <p className="m-0 p-0 f-12">+{element.mobile}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td className="border-left-0">{orderDetailCard(element)}</td>
                                                                    <td className="border-left-0">{element.order_status_message}</td>
                                                                    <td className="border-left-0 text-right">{formatCurrency(parseFloat(element.order_price))}</td>
                                                                    <td className="border-left-0 text-right">{formatCurrency(parseFloat(element.upmark))}</td>
                                                                    <td className="border-left-0 text-right">{formatCurrency(parseFloat(element.upmark_final_pay))}</td>
                                                                    <td className="border-right-0 text-right">{formatCurrency(parseFloat(element.commission_credited))}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table> : <p>No Client order details found for the distributor</p>}
                                            </div>
                                        </div>

                                    </div>

                                    : null}

                                {(curTab === "Client Sell Orders") ?
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                {tableData && tableData.length > 0 ? <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="border-left-0">Name</th>
                                                            <th scope="col" className="border-left-0 orderDetailColWidth">Order Details</th>
                                                            <th scope="col" className="border-left-0">Status</th>
                                                            <th scope="col" className="border-left-0 text-right">Amount</th>
                                                            <th scope="col" className="border-left-0 text-right">Upmark</th>
                                                            <th scope="col" className="border-left-0 text-right">Upmark Final Pay</th>
                                                            <th scope="col" className="border-right-0 text-right">Commission</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tableData.map((element, key) => {
                                                            return (
                                                                <tr key={`tableData` + key}>
                                                                    <td className="border-left-0">
                                                                        <div className="tableBreakWord">
                                                                            <p className="m-0 p-0 f-12 font-weight-bold">{element.user_name}</p>
                                                                            <p className="m-0 p-0 f-12">{element.user_email}</p>
                                                                            <p className="m-0 p-0 f-12">+{element.mobile}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td className="border-left-0">{orderDetailCard(element, "sell")}</td>
                                                                    <td className="border-left-0">{element.order_status_message}</td>
                                                                    <td className="border-left-0 text-right">{formatCurrency(parseFloat(element.order_price))}</td>
                                                                    <td className="border-left-0 text-right">{formatCurrency(parseFloat(element.upmark))}</td>
                                                                    <td className="border-left-0 text-right">{formatCurrency(parseFloat(element.upmark_final_pay))}</td>
                                                                    <td className="border-right-0 text-right">{formatCurrency(parseFloat(element.commission_credited))}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table> : <p>No Client order details found for the distributor</p>}
                                            </div>
                                        </div>

                                    </div>

                                    : null}

                                {(curTab === "Commission") ?
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {kycRequesterUserComplete && kycRequesterUserComplete.commission_modal === "FLAT_RATE" ?
                                                <div className="table-responsive">
                                                    {tableData && tableData.length > 0 ? <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="border-left-0">Date</th>
                                                                <th scope="col" className="border-left-0">Description</th>
                                                                <th scope="col" className="border-left-0 text-right">Credit</th>
                                                                <th scope="col" className="border-left-0 text-right">Debit</th>
                                                                <th scope="col" className="border-right-0 text-right">Balance</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {tableData.map((element, key) => {
                                                                return (
                                                                    <tr key={`tableData` + key}>
                                                                        <td className="border-left-0">
                                                                            <div className="tableBreakWord">
                                                                                <p className="m-0 p-0 f-12 font-weight-bold">{element.date}</p>
                                                                                <p className="m-0 p-0 f-12">{element.time}</p>
                                                                            </div>
                                                                        </td>
                                                                        <td className="border-left-0">{element.description}</td>
                                                                        <td className="border-left-0 text-right">{formatCurrency(parseFloat(element.credit))}</td>
                                                                        <td className="border-left-0 text-right">{formatCurrency(parseFloat(element.debit))}</td>
                                                                        <td className="border-right-0 text-right">{formatCurrency(parseFloat(element.available_balance))}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table> : <p>No Commission details found for the distributor</p>}
                                                </div>
                                                : <button className="btn btn-primary px-4" onClick={(e) => {
                                                    e.preventDefault();
                                                    downloadFile()
                                                }}> Download Commission Sheet</button>}
                                        </div>

                                    </div>

                                    : null}
                            </div>
                        </div>
                            : <div className="loader"></div>}
                    </div>
                </div>
            </div>
        </>
    )
}