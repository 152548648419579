import React, { useState } from "react";
import { useBuyOrderContext } from "../../../services/contexts/BuyOrdersContext";
import { Modal } from "@material-ui/core";
import ToastPage from "../../includes/ToastPage";
import { useLogin } from "../../../services/contexts/AdminLoginContext";
import { configs, formatCurrency } from "../../../assets/Config";
import { updateMandateStatus } from "../../../services/apis/shareManagement/BuyOrdersApi";
import { config } from "dotenv";

export default function MandateModalPage() {

    const { mandateData, setMandateData, setActiveStatus, rejectMandateModal, setRejectMandateModal, paymentMandateModal, setPaymentMandateModal, acknowledgeMandateModal, setAcknowledgeMandateModal, completeMandateModal, setCompleteMandateModal, refundMandateModal, setRefundMandateModal } = useBuyOrderContext();
    const { showSuccess, setShowSuccess, token, logout, setLoginError } = useLogin();

    const [remark, setRemark] = useState("");
    const [utrNumber, setUtrNumber] = useState("")
    const [bankName, setBankName] = useState("")
    const [amount, setAmount] = useState("")
    const [utrList, setUtrList] = useState([])
    const [dateTime, setDateTime] = useState(`${new Date(new Date().getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19)}`)
    const [status, setStatus] = useState("COMPLETE");

    const [error, setError] = useState("");

    // add utr function
    function addUtr() {
        try {
            if (!utrNumber.trim() || utrNumber.length > 30) {
                setError("Please enter a valid UTR number.")
            } else if (!bankName.trim()) {
                setError("Please enter a valid Bank Name.")
            } else if (!amount) {
                setError("Please enter a valid Amount.")
            } else if (!dateTime) {
                setError("Please select a valid date time")
            }
            else {
                let utr = utrList
                utr.push({
                    "utr_num": utrNumber,
                    "bank_name": bankName,
                    "payment_date": dateTime,
                    "amount": amount
                })
                setUtrNumber("")
                setDateTime("")
                setAmount("")
                setUtrList(utr)
                setError("")
            }
        } catch (error) {
            console.error(error);
        }
    }

    // delete utr
    function deleteUtr(element) {
        try {
            let utr = <div className="row">
                <div className="col-md-6 col-12">
                    <div className="form-group">
                        <label>UTR Number</label>
                        <input type="text" className="form-control" placeholder="UTR Number" value={utrNumber ? utrNumber : ""} onChange={(e) => {
                            setUtrNumber(e.target.value)
                        }} />
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="form-group">
                        <label>Bank Name</label>
                        <select name="user_nationality" id=" user_nationality" className="form-control" value={bankName ? bankName : "default"} onChange={(e) => {
                            setBankName(e.target.value)
                        }}>
                            <option disabled value="default"> Select an option </option>
                            <option value="ICICI">ICICI - 000605028824</option>
                            <option value="HDFC">HDFC - 50200017556819</option>
                            <option value="Cashfree">Cashfree PL</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="form-group">
                        <label>UTR Amount</label>
                        <input type="number" className="form-control" placeholder="UTR Amount" value={amount ? amount : ""} onChange={(e) => {
                            setAmount(e.target.value)
                        }} />
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="form-group">
                        <label>UTR Payment Time</label>
                        <input type="datetime-local" className="form-control" placeholder="Payment Timestamp" value={dateTime} onChange={(e) => {
                            setDateTime(e.target.value)
                        }} />
                    </div>
                </div>
            </div>
            let index = utr.indexOf(element)
            utr.splice(index, 1)
            setUtrList(utr)
            setError("")
        } catch (error) {

        }
    }

    async function rejectMandate() {
        try {
            document.getElementById("highValueRejectBtn").classList.toggle("d-none")
            document.getElementById("highValueRejectBtnLoader").classList.toggle("d-none")

            if (!remark) {
                setError("Please enter a valid remark.")
            } else {
                let response = await updateMandateStatus(token, 'cancel', { id: mandateData.id, remark: remark })
                if (response && response.status === 200) {
                    setShowSuccess(true);
                    setActiveStatus(configs.mandateStatusTypes["Cancelled Mandates"].status);
                    handleClose();
                } else if (response && response.status === 401) {
                    logout()
                    setLoginError(configs.unauthorizedErrorMessage)
                } else {
                    setError(response?.data?.error)
                }
            }

            document.getElementById("highValueRejectBtn").classList.toggle("d-none")
            document.getElementById("highValueRejectBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error);
            setError(error.error ? error.error : error);
        }
    }

    // submit utr details
    async function saveUTRDetails() {
        try {

            document.getElementById("saveUTRBtn").classList.toggle("d-none")
            document.getElementById("saveUTRBtnLoader").classList.toggle("d-none")

            if (!utrList) {
                setError("Please enter a valid UTR number to submit.")
            } else {
                let response = await updateMandateStatus(token, 'initiate', { id: mandateData.id, utr: utrList })
                if (response && response.status === 200) {
                    setShowSuccess(true);
                    setActiveStatus(configs.mandateStatusTypes["Acknowledgement Pending"].status);
                    handleClose();
                } else if (response && response.status === 401) {
                    logout()
                    setLoginError(configs.unauthorizedErrorMessage)
                } else {
                    setError(response?.data?.error)
                }
            }

            document.getElementById("saveUTRBtn").classList.toggle("d-none")
            document.getElementById("saveUTRBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error);
            // setError(error.error ? error.error : error)
        }
    }

    // acknowledge mandate and update status
    async function acknowledgeMandate() {
        try {

            document.getElementById("ackOrderBtn").classList.toggle("d-none")
            document.getElementById("ackOrderBtnLoader").classList.toggle("d-none")

            if (!remark) {
                setError("Please enter a valid remark.")
            } else {
                let response = await updateMandateStatus(token, 'acknowledge', { id: mandateData.id, remark: remark })
                if (response && response.status === 200) {
                    setShowSuccess(true);
                    setActiveStatus(configs.mandateStatusTypes["Acknowledged Mandates"].status);
                    handleClose();
                } else if (response && response.status === 401) {
                    logout()
                    setLoginError(configs.unauthorizedErrorMessage)
                } else {
                    setError(response?.data?.error)
                }
            }

            document.getElementById("ackOrderBtn").classList.toggle("d-none")
            document.getElementById("ackOrderBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error);
            // setError(error.error ? error.error : error)
        }
    }

    // acknowledge mandate and update status
    async function completeMandate() {
        try {

            document.getElementById("completeBtn").classList.toggle("d-none")
            document.getElementById("completeBtnLoader").classList.toggle("d-none")

            if (!remark) {
                setError("Please enter a valid remark.")
            } else {
                let response = await updateMandateStatus(token, status === "COMPLETE" ? 'complete' : "initiate-refund", { id: mandateData.id, remark: remark })
                if (response && response.status === 200) {
                    setShowSuccess(true);
                    setActiveStatus(status === "COMPLETE" ? configs.mandateStatusTypes["Completed Mandates"].status : configs.mandateStatusTypes["Refund Initiated"].status);
                    handleClose();
                } else if (response && response.status === 401) {
                    logout()
                    setLoginError(configs.unauthorizedErrorMessage)
                } else {
                    setError(response?.data?.error)
                }
            }

            document.getElementById("completeBtn").classList.toggle("d-none")
            document.getElementById("completeBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error);
            // setError(error.error ? error.error : error)
        }
    }
    // complete mandate refund
    async function completeRefundMandate() {
        try {

            document.getElementById("highValueRefundCompleteBtn").classList.toggle("d-none")
            document.getElementById("highValueRefundCompleteBtnLoader").classList.toggle("d-none")

            if (!remark) {
                setError("Please enter a valid remark.")
            } else {
                let response = await updateMandateStatus(token, "complete-refund", { id: mandateData.id, remark: remark })
                if (response && response.status === 200) {
                    setShowSuccess(true);
                    setActiveStatus(configs.mandateStatusTypes["Refund Completed"].status);
                    handleClose();
                } else if (response && response.status === 401) {
                    logout()
                    setLoginError(configs.unauthorizedErrorMessage)
                } else {
                    setError(response?.data?.error)
                }
            }

            document.getElementById("highValueRefundCompleteBtn").classList.toggle("d-none")
            document.getElementById("highValueRefundCompleteBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error);
            // setError(error.error ? error.error : error)
        }
    }

    const handleClose = () => {
        try {
            setMandateData("");
            setRemark("");
            setUtrNumber("");
            setBankName("");
            setAmount("");
            setUtrList([]);
            setStatus("COMPLETE");
            setError("");
            setRejectMandateModal(false);
            setPaymentMandateModal(false);
            setAcknowledgeMandateModal(false);
            setCompleteMandateModal(false);
            setRefundMandateModal(false);
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <>
            {/* ------------------------------- Reject or cancel mandate modal------------------------------------------------ */}
            < Modal open={rejectMandateModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">Reject Order - {mandateData?.id}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{mandateData?.company?.name}</span></label>
                                    </div>
                                </form>
                                <form action="">
                                    {/* <div className="form-group my-1">
                                        <label>Remark</label>
                                        <select name="" id="" className="col-12 py-1 px-2 border rounded" value={remark ? remark : "0"} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }}>
                                            <option value="0"> -- Select a Remark -- </option>
                                            {remarkComments && remarkComments.templates ? remarkComments.templates.map((element, key) => {
                                                return (
                                                    <option key={`buyorderrejectremark` + key} value={element.template}>{element.template}</option>
                                                )
                                            }) : null}
                                        </select>
                                    </div> */}
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea className="form-control" placeholder="Please write a remark" value={remark ? remark : ""} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }} />
                                    </div>
                                </form>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="addUtrError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button id="highValueRejectBtn" type="button" className="btn btn-danger" onClick={(e) => {
                                    e.preventDefault()
                                    rejectMandate()
                                }}>Reject</button>
                                <div id="highValueRejectBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
            {/* ------------------------------- Payment mandate modal------------------------------------------------ */}
            <Modal open={paymentMandateModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">NEFT / RTGS Details for Order - {mandateData?.id}</h5>
                                <button type="button" className="close" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{mandateData?.company?.name}</span></label>
                                    </div>
                                </form>
                                <p className="mt-4 text-custom-grey font-weight-normal">UTR Details</p>
                                <div className="row d-flex m-0">
                                    {utrList.length > 0 ? utrList.map((element, key) => {
                                        return (<div key={`utrList` + key} id={`utrList` + key} className=" col-lg-4 utr-card">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-9">
                                                    <p className="mb-0">
                                                        <span className="text-primary font-weight-bold">{element?.utr_num}</span> <br />
                                                        <span className="text-custom-grey">{element?.bank_name}</span>
                                                    </p>
                                                </div>
                                                <div className="col-3 text-right">
                                                    <a href="#" className="custom-link text-danger" onClick={(e) => {
                                                        e.preventDefault()
                                                        deleteUtr(element)
                                                        document.getElementById(`utrList` + key).style.display = "none";
                                                    }}><i className="far fa-trash-alt"></i></a>
                                                </div>
                                                <div className="col-6 pr-0">
                                                    <p className="mb-0">{formatCurrency(parseFloat(element.amount))}</p>
                                                </div>
                                                <div className="col-6 pl-0">
                                                    <p className="mb-0 text-right">{element.payment_date}</p>
                                                </div>
                                            </div>
                                        </div>)
                                    }) : null}
                                </div>
                                <p className="mt-4 text-custom-grey font-weight-normal">Enter UTR Details</p>
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="form-group">
                                                <label>UTR Number</label>
                                                <input type="text" className="form-control" placeholder="UTR Number" value={utrNumber ? utrNumber : ""} onChange={(e) => {
                                                    setUtrNumber(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group">
                                                <label>Bank Name</label>
                                                <select name="user_nationality" id=" user_nationality" className="form-control" value={bankName ? bankName : "default"} onChange={(e) => {
                                                    setBankName(e.target.value)
                                                }}>
                                                    <option disabled value="default"> Select an option </option>
                                                    <option value="ICICI">ICICI - 000605028824</option>
                                                    <option value="HDFC">HDFC - 50200017556819</option>
                                                    <option value="Cashfree">Cashfree PL</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group">
                                                <label>UTR Amount</label>
                                                <input type="number" className="form-control" placeholder="UTR Amount" value={amount ? amount : ""} onChange={(e) => {
                                                    setAmount(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group">
                                                <label>UTR Payment Time</label>
                                                <input type="datetime-local" className="form-control" placeholder="Payment Timestamp" value={dateTime} onChange={(e) => {
                                                    setDateTime(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button className="text-primary custom-button" onClick={(e) => {
                                            e.preventDefault()
                                            addUtr()
                                        }}><i className="fas fa-plus-circle mr-2"></i>Add UTR</button>
                                    </div>
                                </form>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="addUtrError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button id="saveUTRBtn" type="button" className="btn btn-primary" onClick={(e) => {
                                    e.preventDefault()
                                    saveUTRDetails()
                                }}>Submit</button>
                                <div id="saveUTRBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div >
            </Modal >
            {/* ------------------------------- Acknowledge mandate modal------------------------------------------------ */}
            <Modal open={acknowledgeMandateModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">Acknowledge Mandate - {mandateData?.id}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{mandateData?.company?.name}</span></label>
                                    </div>
                                </form>

                                <form action="">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea className="form-control" placeholder="Remark" value={remark ? remark : ""} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }} />
                                    </div>
                                </form>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="remarkmodalError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button id="ackOrderBtn" type="button" className="btn btn-primary" onClick={(e) => {
                                    e.preventDefault()
                                    acknowledgeMandate()
                                }}>Acknowledge</button>
                                <div id="ackOrderBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* ------------------------------- Complete mandate modal------------------------------------------------ */}
            <Modal open={completeMandateModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">Move Mandate further - {mandateData?.id}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{mandateData?.company?.name}</span></label>
                                    </div>
                                </form>

                                <form action="">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea className="form-control" placeholder="Remark" value={remark ? remark : ""} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }} />
                                    </div>
                                </form>

                                <form action="">
                                    <div className="form-group">
                                        <label>Status</label>
                                        <select className="form-control" placeholder="Select Status" value={status ? status : ""} onChange={(e) => {
                                            setStatus(e.target.value)
                                        }} >
                                            <option value="COMPLETE">Move to Buy Orders</option>
                                            <option value="REFUND_INITIATED">Initiate Refund</option>
                                        </select>
                                    </div>
                                </form>

                                <div className="form-check p-0">
                                    <p className="text-danger" id="remarkmodalError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button id="completeBtn" type="button" className="btn btn-primary" onClick={(e) => {
                                    e.preventDefault()
                                    completeMandate()
                                }}>Move Forward</button>
                                <div id="completeBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* ------------------------------- Mandate refund modal------------------------------------------------ */}
            < Modal open={refundMandateModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">Complete Refund of Mandate - {mandateData?.id}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{mandateData?.company?.name}</span></label>
                                    </div>
                                </form>
                                <form action="">
                                    {/* <div className="form-group my-1">
                                        <label>Remark</label>
                                        <select name="" id="" className="col-12 py-1 px-2 border rounded" value={remark ? remark : "0"} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }}>
                                            <option value="0"> -- Select a Remark -- </option>
                                            {remarkComments && remarkComments.templates ? remarkComments.templates.map((element, key) => {
                                                return (
                                                    <option key={`buyorderrejectremark` + key} value={element.template}>{element.template}</option>
                                                )
                                            }) : null}
                                        </select>
                                    </div> */}
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea className="form-control" placeholder="Please write a remark or choose from above" value={remark ? remark : ""} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }} />
                                    </div>
                                </form>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="addUtrError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button id="highValueRefundCompleteBtn" type="button" className="btn btn-success" onClick={(e) => {
                                    e.preventDefault()
                                    completeRefundMandate()
                                }}>Complete Refund</button>
                                <div id="highValueRefundCompleteBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
            {/* ------------------------------- Success modal------------------------------------------------ */}
            <ToastPage />
        </>
    )
}