import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import ToastPage from '../../includes/ToastPage';
import '../../../assets/styles/RM.css'
import HDFCAnalyticsPage from './HDFCAnalyticsPage';
import HDFCUpdatePage from './HDFCUpdatePage';
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';
import HDFCReportsPage from './HDFCReportsPage';

export default function HDFCMainPage() {

    const { setCurrModule, allowedFunc, token } = useLogin()
    const { setProcessedData, showReport } = useCMContext();

    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['payment_sheet_parser:payment_sheet_parser'])) {
                    setCurrModule(configs.modules.paymentSheetParser)
                    setProcessedData([])
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    // role management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='rmContainer' className="rm-container">
                    {showReport ? <HDFCReportsPage /> : <HDFCAnalyticsPage />}
                    {allowedFunc.includes(configs.functionList['payment_sheet_parser:payment_sheet_parser']) ?
                        <HDFCUpdatePage />
                        : null}
                    <ToastPage />
                </div>
            </div>
        </>
    )
}