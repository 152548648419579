import React, { useEffect, useState } from "react";
import { useCMContext } from "../../../services/contexts/companyManagement/CMContext";
import { getPaymentSheetHistroy } from "../../../services/apis/shareManagement/InventoryAPI";
import { useLogin } from "../../../services/contexts/AdminLoginContext";
import { Link } from "@material-ui/core";
import { configs } from "../../../assets/Config";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";

export default function HDFCReportsPage() {

    const { showReport, setShowReport } = useCMContext();
    const { setShowSuccess, token, logout, setLoginError, setCurrModule, allowedFunc } = useLogin()

    const [historyId, setHistoryId] = useState("");
    const [reportData, setReportData] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        try {
            setReportData("");
            getHistory(historyId)
        } catch (error) {
            console.error(error);
        }
    }, [historyId])

    async function getHistory(historyId) {
        try {
            let response = await getPaymentSheetHistroy(token, historyId);
            if (response && response.status === 200) {
                setReportData(response.data.data.report ? response.data.data.report : response.data.data);
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else {
                setError(response.data.error)
                setReportData([])
            }

        } catch (error) {
            console.error(error);
            setError("History/Details of saved records could not be fetched.")
        }
    }

    const columns = [{ field: "id", headerName: "Row Id", minWidth: 150, flex: 0.5, hide: true, filterable: false },
    {
        field: "date", headerName: "Date", minWidth: 100, flex: 0.3, cellClassName: "break-text", renderCell: (params) => {
            return params.row.csv_data.Date
        }, valueGetter: (value) => value.row.csv_data.isin
    },
    {
        field: "Narration", headerName: "Narration", minWidth: 150, flex: 0.5, hide: true, cellClassName: "break-text", renderCell: (params) => {
            return params.row.csv_data.Narration
        }, valueGetter: (value) => value.row.csv_data.Narration
    },
    {
        field: "cheque", headerName: "Chq./Ref.No.", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            return params.row.csv_data["Chq./Ref.No."]
        }, valueGetter: (value) => value.row.csv_data["Chq./Ref.No."]
    },
    {
        field: "value_date", headerName: "Value Date", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            return params.row.csv_data["Value Dt"]
        }, valueGetter: (value) => value.row.csv_data["Value Dt"]
    },
    {
        field: "withdrawal", headerName: "Withdrawal Amt.", minWidth: 100, flex: 0.4, cellClassName: "break-text", renderCell: (params) => {
            return params.row.csv_data["Withdrawal Amt."]
        }, valueGetter: (value) => value.row.csv_data["Withdrawal Amt."]
    },
    {
        field: "order_id", headerName: "Order Id", minWidth: 90, flex: 0.2, cellClassName: "break-text", renderCell: (params) => {
            return params.row.csv_data["Order ID"]
        }, valueGetter: (value) => value.row.csv_data["Order ID"]
    },
    {
        field: "ac_no", headerName: "AC No", minWidth: 90, flex: 0.2, cellClassName: "break-text text-right", renderCell: (params) => {
            return params.row.csv_data["Ac No"]
        }, valueGetter: (value) => value.row.csv_data["Ac No"]
    },
    {
        field: "type", headerName: "Type", minWidth: 90, flex: 0.2, cellClassName: "break-text", renderCell: (params) => {
            return params.row.csv_data.Type
        }, valueGetter: (value) => value.row.csv_data.Type
    },
    {
        field: "client_id", headerName: "Client Id", minWidth: 100, flex: 0.4, cellClassName: "break-text", renderCell: (params) => {
            return params.row.user.id
        }, valueGetter: (value) => value.row.user.id
    },
    { field: "row_status", headerName: "Row Status", minWidth: 80, flex: 0.3, cellClassName: "break-text" },
    { field: "message", headerName: "Message", minWidth: 250, flex: 0.5, cellClassName: "break-text" },
    {
        field: "client_email", headerName: "E-mail", minWidth: 250, flex: 0.5, cellClassName: "break-text f-12", renderCell: (params) => {
            if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                return <Link to={`/customerProfile/${params.row.user.id}`} >{params.row.user.email}</Link>
            } else {
                return params.row.user.email
            }
        }, valueGetter: (value) => value.row.user.email
    },
    ]


    const reportColumns = [{ field: "id", headerName: "Report Id", minWidth: 150, flex: 0.5, hide: true, filterable: false },
    {
        field: "admin_name", headerName: "Admin Name", minWidth: 100, flex: 0.3, cellClassName: "break-text", renderCell: (params) => {
            return params.row.admin.name
        }, valueGetter: (value) => value.row.admin.name
    },
    {
        field: "admin_email", headerName: "Admin Email", minWidth: 150, flex: 0.5, hide: true, cellClassName: "break-text", renderCell: (params) => {
            return params.row.admin.email
        }, valueGetter: (value) => value.row.admin.email
    },
    {
        field: "date", headerName: "Date", minWidth: 150, flex: 0.5, cellClassName: "break-text"
    },
    { field: "time", headerName: "Time", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    {
        field: "review", headerName: " ", minWidth: 100, flex: 0.2, cellClassName: "text-primary break-text", filterable: false, renderCell: (params) => {
            return <a href="" className="custom-link" onClick={(e) => {
                e.preventDefault();
                setHistoryId(params.row.id);
            }}>Detailed Report</a>
        }
    }
    ]

    return (
        <div id="inventoryAnalyticsListPage" style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-3 mb-1 mr-2">
                <div className="col-md-12 col-12 p-0">
                    <p className="h4 text-custom-grey">{historyId ?
                        <i className="far fa-arrow-alt-circle-left curPoint" onClick={(e) => {
                            setHistoryId("");
                        }}></i>
                        : null} HDFC Payment Update Report {historyId ? `Detail - ${historyId}` : ""}</p>
                </div>
            </div>
            <div className='row m-0 mx-3 p-0'>
                <div className='col-6 m-0 p-0'>
                    <a href='#' className='custom-link' onClick={(e) => {
                        e.preventDefault()
                        setShowReport(false);
                    }}><p className='m-0 p-0'>View Processed Data</p></a>
                </div>
                <div className='col-6 m-0 p-0 d-md-none d-block'>
                    <a href='#' className='custom-link' onClick={(e) => {
                        e.preventDefault()
                        document.getElementById("updateExportInventory").classList.toggle('d-none')
                        document.getElementById("inventoryAnalyticsListPage").classList.toggle('d-none')
                    }}><p className='m-0 p-0'>Update HDFC Payment Data</p></a>
                </div>
            </div>
            <div className="deleted-role-table">
                {reportData && reportData.length > 0 && historyId ? <DataGrid
                    rows={reportData}
                    headerHeight={40}
                    rowHeight={30}
                    columns={columns}
                    resizable={true}
                    autoPageSize
                    pagination
                    components={{
                        Toolbar: GridToolbar,
                    }} /> : reportData && reportData.length > 0 ? <DataGrid
                        rows={reportData}
                        headerHeight={40}
                        rowHeight={30}
                        columns={reportColumns}
                        resizable={true}
                        autoPageSize
                        pagination
                        components={{
                            Toolbar: GridToolbar,
                        }} /> : <p className={`pt-5 ${error ? "text-danger" : ""}`}>{error ? error : "No Report Data to check."}</p>}
            </div>
        </div>
    )
}